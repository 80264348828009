import { Link } from "gatsby"
import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import * as styles from "./HireDevelopers.module.scss"
const HireDevelopers = ({
  strapiData,
  devSDetails,
  hireProduct,
  hireDev,
  frontendDec,
  apiExplore,
  exploreDev,
}) => {
  const [showMoreSkills, setShowMoreSkills] = useState([false, false, false])

  const toggleShowMoreSkills = id => {
    id === 0
      ? setShowMoreSkills([!showMoreSkills[0], false, false])
      : id === 1
      ? setShowMoreSkills([false, !showMoreSkills[1], false])
      : setShowMoreSkills([false, false, !showMoreSkills[2]])
  }

  return (
    <React.Fragment>
      <div
        className={`${styles.AngularDevelopersWeb} ${
          hireProduct ? styles.hireProductBg : ""
        }`}
      >
        <Container>
          <h2
            className={`${styles.heading} `}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={`${styles.description} ${
              frontendDec ? styles.frontendDesc : ""
            } ${hireDev ? styles.hireDevDec : ""}
              ${apiExplore ? styles.apiExploreDec : ""}
             ${frontendDec ? styles.frontendDesc : ""} ${
              hireDev ? styles.hireDevDec : ""
            }  ${apiExplore ? styles.apiExploreDec : ""} ${
              exploreDev ? styles.exploreDevDec : ""
            }`}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={`${styles.cardWrapper} gap-30`}>
            {devSDetails &&
              devSDetails.map((e, i) => (
                <Col lg={4} md={6} sm={12} xs={12} key={i} className="">
                  <div className={styles.card}>
                    <div className={` d-flex ${styles.developersData}`}>
                      <div className={styles.developerImg}>
                        <img
                          src={e?.devImage}
                          alt={e?.name}
                          className={styles.devImages}
                          decoding="async"
                          loading="lazy"
                        />
                      </div>
                      <div className={styles.devContent}>
                        <h3>{e?.name}</h3>
                        <p className={styles.experience}>{e?.experience}</p>
                      </div>
                    </div>
                    <div className={styles.allSkills}>
                      {e.skills &&
                        e.skills
                          .slice(0, showMoreSkills[i] ? e.skills.length : 5)
                          .map((skill, eIdx) => (
                            <div className={styles.skills} key={eIdx}>
                              <div className={`${styles.skill}`}>{skill}</div>
                            </div>
                          ))}

                      <div className={styles.showMoreBtn}>
                        <button
                          onClick={() => toggleShowMoreSkills(i)}
                          className={styles.btnShowMore}
                        >
                          {!showMoreSkills[i] ? (
                            <>
                              See more
                              <img
                                className={styles.whitePlus}
                                src="https://invozone-backend.s3.us-east-1.amazonaws.com/plus_1cd4c3a082.svg"
                                alt="plus"
                                style={{
                                  height: "16px",
                                  width: "16px",
                                }}
                                decoding="async"
                                loading="lazy"
                              />
                            </>
                          ) : (
                            <> See less</>
                          )}
                        </button>
                      </div>
                    </div>
                    <p className={styles.cardDesc}>{e?.desc}</p>
                    <div className={styles.typedevBtn}>
                      <Link to="#form">
                        <Button className={styles.typeBtn}>
                          <img
                            src="https://invozone-backend.s3.us-east-1.amazonaws.com/hire_developer_e784432b67.svg"
                            alt={e?.name}
                            className={styles.mernIcon}
                            style={{
                              height: "28px",
                              width: "39px",
                            }}
                            decoding="async"
                            loading="lazy"
                          />
                          {`Hire ${e?.name}`}
                        </Button>
                      </Link>
                    </div>
                    <div className={styles.bottomRating}>
                      <img
                        src={e?.rating}
                        alt="stars"
                        style={{ height: "20px", width: "128px" }}
                        decoding="async"
                        loading="lazy"
                      />
                      <div
                        className={styles.review}
                        dangerouslySetInnerHTML={{
                          __html: e?.reviews,
                        }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default HireDevelopers
