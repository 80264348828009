// extracted by mini-css-extract-plugin
export var AngularDevelopersWeb = "HireDevelopers-module--AngularDevelopersWeb--d9ff7";
export var allSkills = "HireDevelopers-module--allSkills--96bcc";
export var apiExploreDec = "HireDevelopers-module--apiExploreDec--90c0f";
export var bottomRating = "HireDevelopers-module--bottomRating--7ede6";
export var btnShowMore = "HireDevelopers-module--btnShowMore--09dfd";
export var card = "HireDevelopers-module--card--6e968";
export var cardDesc = "HireDevelopers-module--cardDesc--8b4d8";
export var cardWrapper = "HireDevelopers-module--cardWrapper--ce448";
export var description = "HireDevelopers-module--description--ad0cf";
export var devContent = "HireDevelopers-module--devContent--93a18";
export var devImages = "HireDevelopers-module--devImages--c6efe";
export var developersData = "HireDevelopers-module--developersData--d8849";
export var experience = "HireDevelopers-module--experience--ecfcb";
export var expert = "HireDevelopers-module--expert--0e831";
export var exploreDevDec = "HireDevelopers-module--exploreDevDec--d4b48";
export var frontendDesc = "HireDevelopers-module--frontendDesc--93c01";
export var heading = "HireDevelopers-module--heading--1b65f";
export var hireDevDec = "HireDevelopers-module--hireDevDec--71135";
export var hireProductBg = "HireDevelopers-module--hireProductBg--de2eb";
export var mernIcon = "HireDevelopers-module--mernIcon--ee1fc";
export var review = "HireDevelopers-module--review--63c7b";
export var skill = "HireDevelopers-module--skill--ceebc";
export var skills = "HireDevelopers-module--skills--d1512";
export var typeBtn = "HireDevelopers-module--typeBtn--4020b";
export var typedevBtn = "HireDevelopers-module--typedevBtn--79c06";
export var whitePlus = "HireDevelopers-module--whitePlus--ded52";